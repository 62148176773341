import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Pickem.css';
import ConfidenceDropdown from './ConfidenceDropdown';
import WinnerDropdown from './WinnerDropdown';
import WeekDropdown from './WeekDropdown';
import { Table, Button, FormControl, Image } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import _ from 'underscore';
import * as moment from 'moment';
import bears from './images/bears.png';
import bengals from './images/bengals.png';
import bills from './images/bills.png';
import broncos from './images/broncos.png';
import browns from './images/browns.png';
import buccaneers from './images/buccaneers.png';
import cardinals from './images/cardinals.png';
import chargers from './images/chargers.png';
import chiefs from './images/chiefs.png';
import colts from './images/colts.png';
import commanders from './images/commanders.png';
import cowboys from './images/cowboys.png';
import dolphins from './images/dolphins.png';
import eagles from './images/eagles.png';
import falcons from './images/falcons.png';
import fortyniners from './images/fortyniners.png';
import giants from './images/giants.png';
import jaguars from './images/jaguars.png';
import jets from './images/jets.png';
import lions from './images/lions.png';
import packers from './images/packers.png';
import panthers from './images/panthers.png';
import raiders from './images/raiders.png';
import rams from './images/rams.png';
import ravens from './images/ravens.png';
import saints from './images/saints.png';
import seahawks from './images/seahawks.png';
import steelers from './images/steelers.png';
import texans from './images/texans.png';
import titans from './images/titans.png';
import vikings from './images/vikings.png';
import patriots from './images/patriots.png';

export default class Pickem extends Component {
	constructor(props) {
		super(props);

		let startDay = moment().startOf("week").add(4, "d").format("MM/DD/YYYY");
		let currentWeek = this.weeks[startDay];

		this.state = {
			teamName: "",
			week: currentWeek,
			confidences: this.schedule[currentWeek]
		};
	}

	render() {
		let games = this.state.confidences || [];
		let usedConfidences = this.getUsedConfidences();
		return (
			<div className="flex-column">
				<div>
					<h1>NFL Pickem</h1>
					<div className="header-actions">
						<WeekDropdown
							selectedWeek={this.state.week}
							weeks={Object.keys(this.schedule)}
							onChangeWeek={this.onChangeWeek} />
						<FormControl
							placeholder="Team Name"
							onChange={this.onChangeTeamName}
							className="picks team-name" />
						<CopyToClipboard onCopy={this.onCopy} text={this.getFormattedPicks()}>
							<Button variant="secondary" className="picks copy">Copy Picks</Button>
						</CopyToClipboard>
					</div>
				</div>
				<div className="games">
					<Table striped bordered size="sm">
						<thead>
							<tr>
								<th>TEAM 1</th>
								<th>@ TEAM 2</th>
								<th>WINNER</th>
								<th>CONFIDENCE</th>
							</tr>
						</thead>
						<tbody>
							{games.map((game, index) =>
								<tr key={index}>
									<td>{game.team1.image}{game.team1.name}</td>
									<td>{game.team2.image}{game.team2.name}</td>
									<td><WinnerDropdown
										game={game}
										onChangeGameWinner={this.onChangeGameWinner} /></td>
									<td><ConfidenceDropdown
										game={game}
										onChangeGameConfidence={this.onChangeGameConfidence}
										onChangeTotalPoints={this.onChangeTotalPoints}
										numberOfGames={games.length}
										usedConfidences={usedConfidences} /></td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</div>
		);
	}

	onCopy = () => {
		console.log("copied!");
	}

	onChangeTeamName = (e) => {
		this.setState({ teamName: e.target.value });
	}

	onChangeGameConfidence = (game, selectedConfidence) => {
		var index = this.state.confidences.indexOf(game);
		var newConfiences = [...this.state.confidences];
		newConfiences[index] = { ...game, confidence: selectedConfidence };
		this.setState({ confidences: newConfiences });
	}

	onChangeGameWinner = (game, selectedWinner) => {
		var index = this.state.confidences.indexOf(game);
		var newConfiences = [...this.state.confidences];
		newConfiences[index] = { ...game, winner: selectedWinner };
		this.setState({ confidences: newConfiences });
	}

	onChangeWeek = (selectedWeek) => {
		this.setState({
			week: selectedWeek,
			confidences: this.schedule[selectedWeek]
		});
	}

	onChangeTotalPoints = (e) => {
		let confidences = this.state.confidences;
		let totalPointsGame = undefined;
		_.each(confidences, (game) => {
			if (_.has(game, "totalPoints"))
				totalPointsGame = game;
		});
		var index = this.state.confidences.indexOf(totalPointsGame);
		var newConfiences = [...confidences];
		newConfiences[index] = { ...totalPointsGame, totalPoints: e.target.value };
		this.setState({ confidences: newConfiences });
	}

	getUsedConfidences() {
		var gamesWithConfidences = _.filter(this.state.confidences, (game) => {
			return game.confidence != null;
		});
		var allConfidences = _.map(gamesWithConfidences, (game) => game.confidence);
		return _.uniq(allConfidences);
	}

	getFormattedPicks = () => {
		let formattedGames = _.map(this.state.confidences, (game) => this.formatGame(game));
		return `Team Name: ${this.state.teamName}\r\r${formattedGames.join("\r").trim()}`;
	}

	formatGame(game) {
		let formattedGame = '';
		if (!game.winner || !game.confidence)
			return '';

		formattedGame += `${game.winner.name}\t\t${game.confidence}`;
		if (game.totalPoints)
			formattedGame += `\t\tTP: ${game.totalPoints}`;
		return formattedGame;
	}

	getImage(src) {
		return <Image
			src={src}
			alt="team logo"
			style={{ height: '30px', width: '40px', paddingRight: '10px' }}
		/>;
	}

	weeks = {
		"10/13/2022": "Week 6",
		"10/20/2022": "Week 7",
		"10/27/2022": "Week 8",
		"11/03/2022": "Week 9",
		"11/10/2022": "Week 10",
		"11/17/2022": "Week 11",
		"11/24/2022": "Week 12",
		"12/01/2022": "Week 13",
		"12/08/2022": "Week 14",
		"12/15/2022": "Week 15",
		"12/22/2022": "Week 16",
		"12/29/2022": "Week 17"
	}

	teams = {
		panthers: {
            image: this.getImage(panthers),
            name: "Panthers"
        },
		bears:  {
            image: this.getImage(bears),
            name: "Bears"
        },
		packers:  {
            image: this.getImage(packers),
            name: "Packers"
        },
		chiefs:  {
            image: this.getImage(chiefs),
            name: "Chiefs"
        },
		jaguars:  {
            image: this.getImage(jaguars),
            name: "Jaguars"
        },
		falcons:  {
            image: this.getImage(falcons),
            name: "Falcons"
        },
		vikings:  {
            image: this.getImage(vikings),
            name: "Vikings"
        },
		titans:  {
            image: this.getImage(titans),
            name: "Titans"
        },
		browns:  {
            image: this.getImage(browns),
            name: "Browns"
        },
		bills:  {
            image: this.getImage(bills),
            name: "Bills"
        },
		jets:  {
            image: this.getImage(jets),
            name: "Jets"
        },
		ravens:  {
            image: this.getImage(ravens),
            name: "Ravens"
        },
		dolphins:  {
            image: this.getImage(dolphins),
            name: "Dolphins"
        },
		commanders:  {
            image: this.getImage(commanders),
            name: "Commanders"
        },
		eagles:  {
            image: this.getImage(eagles),
            name: "Eagles"
        },
		rams:  {
            image: this.getImage(rams),
            name: "Rams"
        },
		colts:  {
            image: this.getImage(colts),
            name: "Colts"
        },
		chargers:  {
            image: this.getImage(chargers),
            name: "Chargers"
        },
		bengals:  {
            image: this.getImage(bengals),
            name: "Bengals"
        },
		seahawks:  {
            image: this.getImage(seahawks),
            name: "Seahawks"
        },
		giants:  {
            image: this.getImage(giants),
            name: "Giants"
        },
		cowboys:  {
            image: this.getImage(cowboys),
            name: "Cowboys"
        },
		fortyNiners:  {
            image: this.getImage(fortyniners),
            name: "49ers"
        },
		buccaneers:  {
            image: this.getImage(buccaneers),
            name: "Buccaneers"
        },
		lions:  {
            image: this.getImage(lions),
            name: "Lions"
        },
		cardinals:  {
            image: this.getImage(cardinals),
            name: "Cardinals"
        },
		steelers:  {
            image: this.getImage(steelers),
            name: "Steelers"
        },
		patriots:  {
            image: this.getImage(patriots),
            name: "Patriots"
        },
		texans:  {
            image: this.getImage(texans),
            name: "Texans"
        },
		saints:  {
            image: this.getImage(saints),
            name: "Saints"
        },
		broncos:  {
            image: this.getImage(broncos),
            name: "Broncos"
        },
		raiders:  {
            image: this.getImage(raiders),
            name: "Raiders"
        },
	}

	schedule = {
		"Week 1": [
			{ team1: this.teams.cowboys, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.eagles, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.vikings, team2: this.teams.bengals, winner: null, confidence: null },
			{ team1: this.teams.fortyNiners, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.jaguars, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.colts, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.chargers, team2: this.teams.washington, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.saints, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.raiders, winner: null, confidence: null, totalPoints: null }
		],
		"Week 2": [
			{ team1: this.teams.giants, team2: this.teams.washington, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.texans, team2: this.teams.browns, winner: null, confidence: null },
			{ team1: this.teams.rams, team2: this.teams.colts, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.bills, team2: this.teams.dolphins, winner: null, confidence: null },
			{ team1: this.teams.patriots, team2: this.teams.jets, winner: null, confidence: null },
			{ team1: this.teams.fortyNiners, team2: this.teams.eagles, winner: null, confidence: null },
			{ team1: this.teams.raiders, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.vikings, team2: this.teams.cardinals, winner: null, confidence: null },
			{ team1: this.teams.falcons, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.cowboys, team2: this.teams.chargers, winner: null, confidence: null },
			{ team1: this.teams.titans, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.chiefs, team2: this.teams.ravens, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.packers, winner: null, confidence: null, totalPoints: null }
		],
		"Week 3": [
			{ team1: this.teams.panthers, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.washington, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.browns, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.chargers, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.falcons, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.broncos, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.raiders, winner: null, confidence: null },
			{ team1: this.teams.buccaneers, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.eagles, team2: this.teams.cowboys, winner: null, confidence: null, totalPoints: null }
		],
		"Week 4": [
			{ team1: this.teams.jaguars, team2: this.teams.bengals, winner: null, confidence: null },
			{ team1: this.teams.washington, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.texans, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.panthers, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.dolphins, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.giants, team2: this.teams.saints, winner: null, confidence: null },
			{ team1: this.teams.titans, team2: this.teams.jets, winner: null, confidence: null },
			{ team1: this.teams.chiefs, team2: this.teams.eagles, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.broncos, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.packers, winner: null, confidence: null },
			{ team1: this.teams.buccaneers, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.raiders, team2: this.teams.chargers, winner: null, confidence: null, totalPoints: null }
		],
		"Week 5": [
			{ team1: this.teams.rams, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.eagles, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.bengals, winner: null, confidence: null },
			{ team1: this.teams.patriots, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.titans, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.washington, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.chargers, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.raiders, winner: null, confidence: null },
			{ team1: this.teams.fortyNiners, team2: this.teams.cardinals, winner: null, confidence: null },
			{ team1: this.teams.giants, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.bills, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.ravens, winner: null, confidence: null, totalPoints: null }
		],
		"Week 6": [
			{ team1: this.teams.commanders, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.fortyNiners, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.patriots, team2: this.teams.browns, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.packers, winner: null, confidence: null },
			{ team1: this.teams.jaguars, team2: this.teams.colts, winner: null, confidence: null },
			{ team1: this.teams.vikings, team2: this.teams.dolphins, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.saints, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.buccaneers, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.panthers, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.bills, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.cowboys, team2: this.teams.eagles, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.chargers, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 7": [
			{ team1: this.teams.saints, team2: this.teams.cardinals, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.ravens, winner: null, confidence: null },
			{ team1: this.teams.buccaneers, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.falcons, team2: this.teams.bengals, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.giants, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.commanders, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.broncos, winner: null, confidence: null },
			{ team1: this.teams.texans, team2: this.teams.raiders, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.chargers, winner: null, confidence: null },
			{ team1: this.teams.chiefs, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.dolphins, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.patriots, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 8": [
			{ team1: this.teams.ravens, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.panthers, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.raiders, team2: this.teams.saints, winner: null, confidence: null },
			{ team1: this.teams.patriots, team2: this.teams.jets, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.eagles, winner: null, confidence: null },
			{ team1: this.teams.titans, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.commanders, team2: this.teams.colts, winner: null, confidence: null },
			{ team1: this.teams.fortyNiners, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.giants, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.browns, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 9": [
			{ team1: this.teams.eagles, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.chargers, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.panthers, team2: this.teams.bengals, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.raiders, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.bills, team2: this.teams.jets, winner: null, confidence: null },
			{ team1: this.teams.vikings, team2: this.teams.commanders, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.cardinals, winner: null, confidence: null },
			{ team1: this.teams.rams, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.titans, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.saints, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 10": [
			{ team1: this.teams.falcons, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.vikings, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.jaguars, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.dolphins, winner: null, confidence: null },
			{ team1: this.teams.texans, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.raiders, winner: null, confidence: null },
			{ team1: this.teams.cowboys, team2: this.teams.packers, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.chargers, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.commanders, team2: this.teams.eagles, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 11": [
			{ team1: this.teams.titans, team2: this.teams.packers, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.panthers, team2: this.teams.ravens, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.commanders, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.eagles, team2: this.teams.colts, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.rams, team2: this.teams.saints, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.raiders, team2: this.teams.broncos, winner: null, confidence: null },
			{ team1: this.teams.chiefs, team2: this.teams.chargers, winner: null, confidence: null },
			{ team1: this.teams.cowboys, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.fortyNiners, team2: this.teams.cardinals, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 12": [
			{ team1: this.teams.bills, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.giants, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.patriots, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.buccaneers, team2: this.teams.browns, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.texans, team2: this.teams.dolphins, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.jets, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.falcons, team2: this.teams.commanders, winner: null, confidence: null },
			{ team1: this.teams.chargers, team2: this.teams.cardinals, winner: null, confidence: null },
			{ team1: this.teams.raiders, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.rams, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.eagles, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.colts, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 13": [
			{ team1: this.teams.bills, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.ravens, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.jaguars, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.commanders, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.titans, team2: this.teams.eagles, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.chiefs, team2: this.teams.bengals, winner: null, confidence: null },
			{ team1: this.teams.chargers, team2: this.teams.raiders, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.buccaneers, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 14": [
			{ team1: this.teams.raiders, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.bengals, winner: null, confidence: null },
			{ team1: this.teams.texans, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.vikings, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.eagles, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.jaguars, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.chargers, winner: null, confidence: null },
			{ team1: this.teams.buccaneers, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.panthers, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.chiefs, team2: this.teams.broncos, winner: null, confidence: null },
			{ team1: this.teams.patriots, team2: this.teams.cardinals, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 15": [
			{ team1: this.teams.fortyNiners, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.bills, winner: null, confidence: null },
			{ team1: this.teams.ravens, team2: this.teams.browns, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.falcons, team2: this.teams.saints, winner: null, confidence: null },
			{ team1: this.teams.giants, team2: this.teams.commanders, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.eagles, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.chiefs, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.cowboys, team2: this.teams.jaguars, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.jets, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.broncos, winner: null, confidence: null },
			{ team1: this.teams.titans, team2: this.teams.chargers, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.patriots, team2: this.teams.raiders, winner: null, confidence: null },
			{ team1: this.teams.rams, team2: this.teams.packers, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 16": [
			{ team1: this.teams.jaguars, team2: this.teams.jets, winner: null, confidence: null },
			{ team1: this.teams.falcons, team2: this.teams.ravens, winner: null, confidence: null },
			{ team1: this.teams.lions, team2: this.teams.panthers, winner: null, confidence: null },
			{ team1: this.teams.bills, team2: this.teams.bears, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.browns, winner: null, confidence: null },
			{ team1: this.teams.seahawks, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.giants, team2: this.teams.vikings, winner: null, confidence: null },
			{ team1: this.teams.bengals, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.texans, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.commanders, team2: this.teams.fortyNiners, winner: null, confidence: null },
			{ team1: this.teams.eagles, team2: this.teams.cowboys, winner: null, confidence: null },
			{ team1: this.teams.raiders, team2: this.teams.steelers, winner: null, confidence: null },
			{ team1: this.teams.packers, team2: this.teams.dolphins, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.rams, winner: null, confidence: null },
			{ team1: this.teams.buccaneers, team2: this.teams.cardinals, winner: null, confidence: null },
			{ team1: this.teams.chargers, team2: this.teams.colts, winner: null, confidence: null, totalPoints: null  }
		],
		"Week 17": [
			{ team1: this.teams.cowboys, team2: this.teams.titans, winner: null, confidence: null },
			{ team1: this.teams.cardinals, team2: this.teams.falcons, winner: null, confidence: null },
			{ team1: this.teams.steelers, team2: this.teams.ravens, winner: null, confidence: null },
			{ team1: this.teams.bears, team2: this.teams.lions, winner: null, confidence: null },
			{ team1: this.teams.jaguars, team2: this.teams.texans, winner: null, confidence: null },
			{ team1: this.teams.broncos, team2: this.teams.chiefs, winner: null, confidence: null },
			{ team1: this.teams.dolphins, team2: this.teams.patriots, winner: null, confidence: null },
			{ team1: this.teams.colts, team2: this.teams.giants, winner: null, confidence: null },
			{ team1: this.teams.saints, team2: this.teams.eagles, winner: null, confidence: null },
			{ team1: this.teams.panthers, team2: this.teams.buccaneers, winner: null, confidence: null },
			{ team1: this.teams.browns, team2: this.teams.commanders, winner: null, confidence: null },
			{ team1: this.teams.fortyNiners, team2: this.teams.raiders, winner: null, confidence: null },
			{ team1: this.teams.jets, team2: this.teams.seahawks, winner: null, confidence: null },
			{ team1: this.teams.vikings, team2: this.teams.packers, winner: null, confidence: null },
			{ team1: this.teams.rams, team2: this.teams.chargers, winner: null, confidence: null },
			{ team1: this.teams.bills, team2: this.teams.bengals, winner: null, confidence: null, totalPoints: null  }
		]
	};
}
