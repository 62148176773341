import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Pickem.css';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export default class WinnerDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = { selectedWinner: null };
	}

	render() {
		let teams = [this.props.game.team1, this.props.game.team2];
		let winner = null
		if (this.props.game.winner) {
			winner = (
				<span>
					{this.props.game.winner.image}<span>{this.props.game.winner.name}</span>
				</span>
			);
		}
		
		return (
			<DropdownButton size="sm" title={winner || "--"}>
				{teams.map(team => 
				<Dropdown.Item eventKey={team.name} key={team.name} onSelect={() => this.selectWinner(team)}>
					{this.getTeam(team)}
				</Dropdown.Item>)}
			</DropdownButton>
		);
	}

	getTeam(team) {
		return <span>
			{team.image}<span>{team.name}</span>
		</span>;
	}

	selectWinner(selectedWinner) {
		this.setState({ selectedWinner: selectedWinner || "--" });
		this.props.onChangeGameWinner(this.props.game, selectedWinner);
	}
}
