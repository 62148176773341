import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Item.css';

export default class Item extends Component {
	render() {
		var { imageSrc, label } = this.props;
		return (
			<div className="root">
				<img src={imageSrc} className="image" alt="competency"/>
				<span className="label">{label}</span>
			</div>
		);
	}
}
