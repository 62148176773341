import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Pickem.css';
import { Dropdown, DropdownButton, InputGroup, Form, ButtonToolbar } from 'react-bootstrap';
import _ from 'underscore';

export default class ConfidenceDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedConfidence: "--",
			selectedTotalPoints: undefined
		};
	}

	render() {
		let confidences = this.getConfidenceArray(this.props.usedConfidences)
		return (
			<ButtonToolbar>
				<DropdownButton size="sm" title={this.state.selectedConfidence || "--"}>
					<Dropdown.Item eventKey={0} key={0} onSelect={() => this.selectConfidence(null)}>No Confidence</Dropdown.Item>
					<Dropdown.Divider />
					{confidences.map(c => <Dropdown.Item eventKey={c} key={c} onSelect={() => this.selectConfidence(c)}>{c}</Dropdown.Item>)}
				</DropdownButton>
				{_.has(this.props.game, "totalPoints") &&
					<InputGroup className="total-points" size="sm">
						<InputGroup.Prepend>
							<InputGroup.Text id="inputGroup-sizing-default">Total Points</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							type="number"
							onChange={(e) => this.props.onChangeTotalPoints(e)}
						/>
					</InputGroup>}
			</ButtonToolbar>
		);
	}

	getConfidenceArray(usedConfidences) {
		let confidences = [];
		let confidenceMax = this.props.numberOfGames
		for (let i = 1; i <= confidenceMax; i++) {
			confidences.push(i);
		}
		return _.difference(confidences, usedConfidences).reverse();
	}

	selectConfidence(selectedConfidence) {
		this.setState({ selectedConfidence: selectedConfidence || "--" });
		this.props.onChangeGameConfidence(this.props.game, selectedConfidence);
	}
}
