import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Router from './Router';

export default class App extends Component {
	render() {
		return (
			<div className="app">
				<Router />
			</div>
		);
	}
}
