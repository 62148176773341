import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Pickem.css';
import { Dropdown } from 'react-bootstrap';

export default class WeekDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = { selectedWeek: this.props.selectedWeek };
	}

	render() {
		return (
			<Dropdown className="header-action">
				<Dropdown.Toggle id="week">{this.state.selectedWeek}</Dropdown.Toggle>
				<Dropdown.Menu>
					{this.props.weeks.map(t => <Dropdown.Item eventKey={t} key={t} onSelect={() => this.selectWeek(t)}>{t}</Dropdown.Item>)}
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	selectWeek(selectedWeek) {
		this.setState({ selectedWeek: selectedWeek });
		this.props.onChangeWeek(selectedWeek);
	}
}
