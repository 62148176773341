import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Resume.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faAngleDown, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Item from './Item';
import androidstudio from '../images/androidstudio.png';
import aws from '../images/aws.png';
import csharp from '../images/csharp.png';
import git from '../images/git.png';
import html from '../images/html.png';
import javascript from '../images/javascript.png';
import kotlin from '../images/kotlin.png';
import macos from '../images/macos.png';
import microsoftsql from '../images/microsoftsql.png';
import msoe from '../images/msoe.png';
import postgres from '../images/postgres.png';
import reactjs from '../images/reactjs.png';
import swagger from '../images/swagger.png';
import targetprocess from '../images/targetprocess.png';
import visualstudio from '../images/visualstudio.png';
import visualstudiocode from '../images/visualstudiocode.png';
import windows from '../images/windows.png';

export default class Resume extends Component {
	componentDidMount() {
		window.addEventListener("scroll", this.onScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.onScroll);
	}

	onScroll() {
		for (var item of document.querySelectorAll(".card-md")) {
			let top = item.offsetTop;

			let isOverBottom = top > (window.pageYOffset + window.innerHeight);

			if (!isOverBottom) {
				item.classList.add("resume-show");
			}
		}
	}

    render() {
        return (
        <div className="resume">
            <div className="top-banner"></div>
            <div className="intro-section">
                <div className="card-sm center dark-background">
                    <div className="title-1">Rebecca Lijewski</div>
                    <div className="title-2">Full Stack Staff Software Engineer</div>
                    <div className="contact">
                        <a href="https://www.linkedin.com/in/rebecca-lijewski-78017a55">
                            <FontAwesomeIcon icon={faLinkedin} color="#e2dbd0" size="2x" />
                        </a>
                        <a href="mailto:rebecca.lijewski@outlook.com">
                            <FontAwesomeIcon icon={faEnvelope} color="#e2dbd0" size="2x" />
                        </a>
                        <a href="https://s3.amazonaws.com/lijewski.info/Rebecca+Lijewski+Resume.pdf" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFilePdf} color="#e2dbd0" size="2x"/>
                        </a>
                    </div>
                </div>
                <div className="more-arrows">
                    <div><FontAwesomeIcon icon={faAngleDown} color="#4C614A" size="2x" className="more-arrow-1" /></div>
                    <div><FontAwesomeIcon icon={faAngleDown} color="#4C614A" size="2x" className="more-arrow-2" /></div>
                    <div><FontAwesomeIcon icon={faAngleDown} color="#4C614A" size="2x" className="more-arrow-3" /></div>
                </div>
            </div>
            <div className="section">
                <div className="card-md wide center medium-background">
                    <div className="title-2">Core Competencies</div>
                    <div className="title-3">Operating Systems</div>
                    <div className="operating-systems center">
                        <img src={windows} alt="windows" />
                        <img src={macos} alt="macos" />
                    </div>
                    <div className="title-3">Languages &amp; Frameworks</div>
                    <div className="competencies">
                        <div className="group">
                            <Item imageSrc={csharp} label="C#" />
                            <Item imageSrc={aws} label="AWS" />
                            <Item imageSrc={kotlin} label="Kotlin" />
                            <Item imageSrc={reactjs} label="ReactJS" />
                        </div>
                        <div className="group">
                            <Item imageSrc={html} label="HTML" />
                            <Item imageSrc={javascript} label="Javascript" />
                            <Item imageSrc={microsoftsql} label="Microsoft SQL" />
                            <Item imageSrc={postgres} label="Postgres" />
                        </div>
                    </div>
                    <div className="title-3">Tools</div>
                    <div className="competencies">
                        <div className="group">
                            <Item imageSrc={visualstudio} label="Visual Studio" />
                            <Item imageSrc={visualstudiocode} label="Visual Studio Code" />
                            <Item imageSrc={androidstudio} label="Android Studio" />
                        </div>
                        <div className="group">
                            <Item imageSrc={git} label="Git" />
                            <Item imageSrc={swagger} label="Swagger" />
                            <Item imageSrc={targetprocess} label="Target Process" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="card-md wide center medium-background experience">
                    <div className="title-2">Experience</div>
                    <div className="title-3"><b>Staff Software Engineer</b> at Direct Supply Inc</div>
                    <div className="title-4">July 2018 - present</div>
                    <div className="title-3"><b>Senior Software Engineer</b> at Direct Supply Inc</div>
                    <div className="title-4">September 2015 - July 2018</div>
                    <div className="title-3"><b>Software Engineer</b> at Direct Supply Inc</div>
                    <div className="title-4">May 2013 - September 2015</div>
                    <div className="title-3"><b>Software Engineer Intern</b> at Direct Supply Inc</div>
                    <div className="title-4">November 2010 - May 2013</div>
                </div>
            </div>
            <div className="section">
                <div className="card-md wide center medium-background">
                    <div className="title-2">Education</div>
                    <div className="education">
                        <img src={msoe} className="image" alt="competency" />
                        <div className="education-details">
                            <div className="title-3">Milwaukee School of Engineering</div>
                            <div className="extra-details">Bachelors of Science in Software Engineering - May 2013</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>); 
    }
}