import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Pickem from './pickem/Pickem';
import Resume from './resume/Resume';

export default class Router extends Component {
	render() {
		return (
			<main>
				<Switch>
					<Route exact path="/" component={Resume} />
					<Route exact path="/home" component={Resume} />
					<Route exact path="/pickem" component={Pickem} />
				</Switch>
			</main>
		);
	}
}
